<template>
    <div>
        <auth-layout>
            <slot></slot>
            <vue-recaptcha ref="recaptcha" v-if="isCaptchaEnabled"
                           @verify="onCaptchaVerified"
                           size="invisible"
                           sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG"
                            />
            <div class="row vh-100 overflow-x-hidden">
                <div class="col-12 col-lg-6 bg-white rounded-lg mr-0 mr-md-minus-5px z-index-1 shadow">
                    <div class="d-flex align-items-start flex-column h-100">
                        <div class="my-auto w-100">
                            <div class="row justify-content-center">
                                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                                    <div class="mx-auto" :class="[{ 'login-w-limit': activePage=='login' }]">
                                        <div class="logo-wrapper text-center text-lg-left mb-2 mb-lg-5 pb-2">
                                            <img src="../../../assets/img/island-candos-logo.png" height="50"/>
                                        </div>
                                       <h4 class="text-center mb-5 d-block d-lg-none">{{ $t('student_information_system') }}</h4>
                                        <div class="fadeIn" v-if="activePage=='login'">
                                            <login-view ref="login" @captcha="onCaptcha" :is-captcha-enabled="isCaptchaEnabled"></login-view>
                                        </div>
                                        <div class="fadeIn" v-if="activePage=='forgot-password'">
                                            <!-- <reset-password-with-mobile-number ref="resetPasswordSms" /> -->
<!--                                            <b-tabs content-class="pt-5 position-relative" justified>-->
<!--                                                <div class="tabs-bottom-line"></div>-->
<!--                                                <b-tab :title="$t('with_personal_information')" active>-->
<!--                                                    <b-tabs v-model="personalInformationActiveTabButton"-->
<!--                                                            justified-->
<!--                                                            class="button-tabs"-->
<!--                                                            content-class="py-4 position-relative"-->
<!--                                                    >-->
<!--                                                        <b-tab :title="$t('student').toUpper()"-->
<!--                                                               :title-link-class="personalInformationButtonTabClass(0)"-->
<!--                                                        >-->
<!--                                                            <reset-password-student ref="resetPasswordStudent" ></reset-password-student>-->
<!--                                                        </b-tab>-->
<!--                                                        <b-tab :title="$t('staff').toUpper()"-->
<!--                                                               :title-link-class="personalInformationButtonTabClass(1)">-->
<!--                                                            <reset-password-staff ref="resetPasswordStaff"></reset-password-staff>-->
<!--                                                        </b-tab>-->
<!--                                                        <b-tab :title="$t('graduate').toUpper()"-->
<!--                                                               :title-link-class="personalInformationButtonTabClass(2)"-->
<!--                                                        >-->
<!--                                                            <reset-password-alumni ref="resetPasswordAlumni"></reset-password-alumni>-->
<!--                                                        </b-tab>-->
<!--                                                    </b-tabs>-->
<!--                                                </b-tab>-->
<!--                                                <b-tab :title="$t('with_mobile_number')">-->
<!--
<!--                                                </b-tab>-->
<!--                                            </b-tabs>-->
                                            <a class="small text-uppercase text-center d-block text-muted cursor-pointer" @click="changeActivePage('login')">
                                                <i class="ri-arrow-left-line mr-2 h6 font-weight-normal top-plus-2"></i>
                                                {{ $t('back').toUpper() }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 p-0 bg-cover-center ml-minus-5px d-none d-lg-block login-right-img"
                     v-bind:style="{'background-image':`url('${$store.getters['dashboard/getImage']('login-background.png')}')`}">
                    <!-- <div class="login-text">
                        {{ $t('bahcesehir_university') }} <br> {{ $t('student_information_system') }}
                    </div> -->
                </div>
            </div>
        </auth-layout>
    </div>
</template>
<script>
    // Template
    import AuthLayout from '@/layouts/AuthLayout'

    // Component
    // import ResetPasswordStudent from './ResetPasswordStudent';
    // import ResetPasswordStaff from './ResetPasswordStaff';
    // import ResetPasswordAlumni from './ResetPasswordAlumni';
    // import ResetPasswordWithMobileNumber from './ResetPasswordWithMobileNumber';
    // import LanguageSwitch from './LanguageSwitch';
    import LoginView from './Login';
    import {EventBus} from "@/main";


    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        components: {
            AuthLayout,
            ValidationProvider,
            ValidationObserver,
            // ResetPasswordStudent,
            // ResetPasswordStaff,
            // ResetPasswordAlumni,
            // ResetPasswordWithMobileNumber,
            // LanguageSwitch,
            LoginView,
            VueRecaptcha
        },
        metaInfo() {
            return {
                title: this.$t('login')
            }
        },
        data() {
            return {
                // Login
                loginPasswordShowStatus: false,
                loginForm: {
                    email: '',
                    password: ''
                },

                // PasswordReset
                passwordResetPageTab: 'gsm',
                personalInformationActiveTabButton: 0,
                child:'login',
                isCaptchaEnabled:true
            };
        },
        mounted() {
            return EventBus.$on('captcha',(arg)=>{
                this.onCaptcha(arg)
            })
        },
        created() {
            if(process.env.VUE_APP_DISABLE_CAPTCHA){
                this.isCaptchaEnabled=false;
            }
            else {
                fetch("https://api.ipify.org?format=json")
                    .then(response => response.json())
                    .then(data => {
                        if(data.ip=='176.236.75.2'){
                            this.isCaptchaEnabled=false;
                        }
                    });
            }
        },
        computed: {
            activePage() {
                return this.$store.getters['auth/getActivePage'];
            },
        },
        watch: {
            timerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
            },
            timerCount: {
                handler(value) {
                    if (value > 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                    else if (value > 0 && !this.timerEnabled) {
                        this.alertShow = false;
                    }
                    else {
                        this.alertVariant = 'danger';
                        this.alertShow = true;
                    }
                },
                immediate: true
            }
        },

        methods: {
            onCaptcha(child){
                this.$refs.recaptcha.execute();
                this.child=child;
            },
            onCaptchaVerified: function (recaptchaToken) {
                if(this.child=='login'){
                    this.$refs.login.onCaptchaVerified(recaptchaToken);
                }
                else if(this.child=='resetPasswordStudent'){

                    this.$refs.resetPasswordStudent.onCaptchaVerified(recaptchaToken);
                }
                else if(this.child=='resetPasswordStaff'){
                    this.$refs.resetPasswordStaff.onCaptchaVerified(recaptchaToken);
                }
                else if(this.child=='resetPasswordAlumni'){
                    this.$refs.resetPasswordAlumni.onCaptchaVerified(recaptchaToken);
                }
                else if(this.child=='resetPasswordSms'){
                    this.$refs.resetPasswordSms.onCaptchaVerified(recaptchaToken);
                }
                this.$refs.recaptcha.reset();
            },
            changeActivePage(page) {
                this.$store.commit('auth/setActivePage', page);
            },
            personalInformationButtonTabClass(no) {
                if (this.personalInformationActiveTabButton === no) {
                    return ['btn btn-outline-primary py-0'];
                }
                else {
                    return ['btn btn-outline-secondary py-0'];
                }
            }
        }
    };
</script>
