import Api from '@/services/Index';

const login = async (user) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_AUTH;
    return Api.post('/v1/housing/tenants/login', user);
}

export default {
    login
}
